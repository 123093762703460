import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import HomeContent from "../../components/HomeContent/HomeContent";

import Blog from "../../routes/Blog/Blog";
import Resources from "../../routes/Resources/Resources";
import News from "../../routes/News/News";
import ServicesList from "../../components/ServiceList/ServicesList";
import FAQSection from "../FAQSection/FAQSection";
import { faqData } from "../FAQSection/FAQDataSection";
import FeedbackSection from "../Feedbacks/FeedbackSection";
import FoundationContent from "../../components/Foundation/FoundationContent";
// import OurStaffh from "../Teams/OurStaffh";
const Home = () => {
  return (
    <>
      <Navbar />
      <HomeContent />
      <h1 style={{ textAlign: "center", margin: 0, padding: "15px" }}>
        Service We provide
      </h1>
      <h3 style={{ textAlign: "center" }}>
        At our organization, we prioritize independence and offer a
        comprehensive range of eleven essential disability care services as a
        trusted NDIS provider.
      </h3>
      <ServicesList />
      {/* <OurStaffh/> */}
      <FoundationContent isTrue={false} />
      <Blog />
      <FoundationContent isTrue={true} />
      <Resources />
      <News />
      <FeedbackSection />
      <h1 style={{ textAlign: "center" }}>Frequently Asked Questions</h1>
      <br />
      <FAQSection faqData={faqData} />
    </>
  );
};

export default Home;
