import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroContent from "../../components/HeroContent/HeroContent";
import EmployeeImg from "../../Image/dncc2.jpeg";
import EmployeeGroup from "../../components/EmployeeGroup/EmployeeGroup";

const Employee = () => {
  return (
    <>
      <Navbar />
      <HeroContent
        cName="hero"
        heroImage={EmployeeImg}
        heroImagecName="heroImage"
      />
      <EmployeeGroup />
    </>
  );
};
export default Employee;
