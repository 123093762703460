import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroContent from "../../components/HeroContent/HeroContent";
import JoinUsImg from "../../Image/dncc5.jpeg";

const JoinUs = () => {
  return (
    <>
      <Navbar />
      <>
        <HeroContent
          cName="hero"
          heroImage={JoinUsImg}
          heroImagecName="heroImage"
        />
      </>
    </>
  );
};

export default JoinUs;
