import { Component } from "react";
import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import cbLogo from "../../Image/Logo.jpeg";
import { NavbarMenuData } from "./NavbarMenuData";

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  render() {
    return (
      <nav className="NavbarItems">
        <Link
          className="navbar-logo"
          to="//dignitynchoicecare.com.au"
          target="_self"
          rel="noreferrer"
        >
          <img src={cbLogo} alt="DNCC logo"></img>
        </Link>
        <div className="nav_website">Dignity & Choice Care Pty. Ltd.</div>

        <div className="menu-icons" onClick={this.handleClick}>
          <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"} />
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {NavbarMenuData.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item.url} className={item.cName}>
                  <i className={item.icon}></i>
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
