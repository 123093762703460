import { React } from "react";
import "./ServicesList.css";

function ServicesListData(props) {
  return (
    <div className="s-card">
      <div className="s-image">
        <img src={props.image} alt="106" />
      </div>
      <h4>{props.heading} </h4>
      {/* <p>{props.text}</p> */}
    </div>
  );
}

export default ServicesListData;
