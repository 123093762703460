export const resources = [
  {
    title: "Assistive Technology",
    description:
      "Learn more about assistive technology and how it can help people with disabilities.",
    link: "",
    icon: "fas fa-external-link-alt",
  },
  {
    title: "Accessible PDFs",
    description:
      "Find out how to create accessible PDFs that are easy to read for everyone.",
    link: "",
    icon: "fa fa-download",
  },
  {
    title: "Disability Rights",
    description:
      "Learn about the rights of people with disabilities and how to advocate for them.",
    link: "",
    icon: "fas fa-external-link-alt",
  },
];
