import React from "react";
import "./HeroContent.css";

function HeroContent(props) {
  return (
    <div className={props.cName}>
      <img
        className={props.heroImagecName}
        src={props.heroImage}
        alt="HeroImage"
      ></img>
      <div className="hero-text"></div>
    </div>
  );
}

export default HeroContent;
