import React from "react";
import FeedbackSection from "./FeedbackSection";
import ContactForm from "../Forms/ContactForm";

const Feedbacks = () => {
  return (
    <>
      <FeedbackSection />
      <ContactForm />
    </>
  );
};

export default Feedbacks;
