import React from "react";
import styles from "./HomeContent.module.css";
import image1 from "../../Image/dncc1.jpeg";
import HomeContentData from "./HomeContentData";

function HomeContent() {
  return (
    <>
      <div className={styles.myComponent}>
        <div className={styles.right}>
          <h1>{HomeContentData.title}</h1>
          <h1>{HomeContentData.title2}</h1>
          <p>{HomeContentData.description}</p>
        </div>
        <div className={styles.left}>
          <img src={image1} alt="My" />
        </div>
      </div>
    </>
  );
}

export default HomeContent;
