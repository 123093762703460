import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroContent from "../../components/HeroContent/HeroContent";
import AboutImg from "../../Image/dncc2.jpeg";
import AboutUs from "../../components/AboutUs/AboutUs";

const About = () => {
  return (
    <>
      <Navbar />
      <HeroContent
        cName="hero"
        heroImage={AboutImg}
        heroImagecName="heroImage"
      />
      <AboutUs />
    </>
  );
};

export default About;
