import React from "react";
import HeroContent from "../../components/HeroContent/HeroContent";
import Navbar from "../../components/Navbar/Navbar";
import cbLogo from "../../Image/Logo.jpeg";

const PageNotFound = () => {
  return (
    <>
      <Navbar />
      <HeroContent cName="hero" heroImage={cbLogo} title="404 Page not Found" />
      <div>
        <h1>Go to Home Page ...!!</h1>
      </div>
    </>
  );
};

export default PageNotFound;
