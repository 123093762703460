import { Component } from "react";
import "./EmployeeGroup.css";

class EmployeeGroupData extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div className="firstEmployeeGroupText">
          <h2>{this.props.heading}</h2>
          <p>{this.props.text}</p>
        </div>
        <div className="image">
          <img src={this.props.employeeGroupImage1} alt="img"></img>
        </div>
      </div>
    );
  }
}
export default EmployeeGroupData;
