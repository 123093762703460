// export const NavbarMenuData = [
//     {
//       title: "Apply",
//       url: "/apply",
//       cName: "nav-links-mobile"
//     }
//   ];

export const NavbarMenuData = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
  },
  {
    title: "Services",
    url: "/services",
    cName: "nav-links",
    icon: "fa-solid fa-briefcase fa-beat",
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
    icon: "fa-solid fa-address-card fa-beat",
  },

  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
    icon: "fa-solid fa-address-book fa-beat",
  },
  {
    /*
    title: "Teams",
    url: "/teams",
    cName: "nav-links",
    icon: "fa-solid fa-user-group fa-beat fa-2xs",
*/
  },
  {
    title: "Feedbacks",
    url: "/feedbacks",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info fa-beat",
  },
  {
    title: "News",
    url: "/news",
    cName: "nav-links",
    icon: "fa-solid fa-rss fa-shake fa-2xs",
  },
  {
    title: "Blog",
    url: "/blog",
    cName: "nav-links",
    icon: "fa-solid fa-book fa-2xs",
  },
];
