import React from "react";
import "./Blog.css";
import { useState } from "react";

const Blog = () => {
  const [isHidden1, setIsHidden1] = useState(true);
  const [isHidden2, setIsHidden2] = useState(true);
  const [isHidden3, setIsHidden3] = useState(true);
  const [isHidden4, setIsHidden4] = useState(true);
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const [showMore4, setShowMore4] = useState(false);

  const toggleIsHidden1 = () => {
    setIsHidden1(!isHidden1);
  };

  const toggleShowMore1 = () => {
    setShowMore1(!showMore1);
  };
  const toggleIsHidden2 = () => {
    setIsHidden2(!isHidden2);
  };

  const toggleShowMore2 = () => {
    setShowMore2(!showMore2);
  };
  const toggleIsHidden3 = () => {
    setIsHidden3(!isHidden3);
  };

  const toggleShowMore3 = () => {
    setShowMore3(!showMore3);
  };
  const toggleIsHidden4 = () => {
    setIsHidden4(!isHidden4);
  };

  const toggleShowMore4 = () => {
    setShowMore4(!showMore4);
  };
  const pElements1 = [
    {
      id: 1,
      content:
        "Improved physical health. Adaptive sports can help people with disabilities improve their overall physical health and fitness levels. By participating in regular exercise, individuals can build strength, increase endurance, and improve cardiovascular health.",
    },
    {
      id: 2,
      content:
        "Increased social connections. Participating in adaptive sports can also offer opportunities for social connections and community building. Many adaptive sports programs are designed to be inclusive and welcoming, providing a space for individuals with disabilities to connect with others who share their interests.",
    },
    {
      id: 3,
      content:
        "PBoosted self-confidence. Adaptive sports can also have a positive impact on self-confidence and self-esteem. By participating in sports and physical activity, individuals with disabilities can develop a sense of accomplishment and pride in their abilities.",
    },
    {
      id: 4,
      content:
        "Greater independence. Finally, adaptive sports can help people with disabilities develop greater independence and self-sufficiency. By participating in sports and physical activity, individuals can learn new skills and gain greater control over their bodies.",
    },
    {
      id: 5,
      content:
        " In conclusion, adaptive sports offer a range of physical, social, and emotional benefits for people with disabilities. If you or someone you know is interested in participating in adaptive sports, there are many resources and programs available to get started. Contact us to learn more about the opportunities available in your community.",
    },
  ];
  const pElements2 = [
    {
      id: 1,
      content:
        "Access to technology: One of the biggest challenges for individuals with disabilities when it comes to online learning is access totechnology. Students with visual or hearing impairments, forexample, may require assistive technologies such as screen readersor captioning software in order to fully participate in onlineclasses.",
    },
    {
      id: 2,
      content:
        "Accommodations for learning styles: Another challenge for studentswith disabilities is ensuring that the online learning experience istailored to their specific learning styles. Students with learningdisabilities may require additional accommodations such as extratime on tests or alternative methods for completing assignments.",
    },
    {
      id: 3,
      content:
        "Support from instructors: In online learning environments, it can bemore difficult for students with disabilities to connect withinstructors and receive the support they need. It's important forinstructors to be aware of the needs of students with disabilitiesand to provide individualized support as needed.",
    },
    {
      id: 4,
      content:
        "Access to resources: Finally, online learning can present challengeswhen it comes to accessing resources such as textbooks, onlinematerials, and support services. It's important for online learningplatforms to be designed with accessibility in mind, and forinstitutions to provide comprehensive resources for students withdisabilities.",
    },
    {
      id: 5,
      content:
        "While online learning can present unique challenges for individualswith disabilities, there are strategies and resources available tohelp students succeed. By working with instructors and utilizingassistive technologies and accommodations, students withdisabilities can thrive in online learning environments. If you orsomeone you know is struggling with online learning, contact us tolearn more about the resources and support available.",
    },
  ];
  const pElements3 = [
    {
      id: 1,
      content:
        "Self-determination: Self-advocacy is essential for promoting self-determination, or the ability to make choices and decisions that impact one's own life. For people with disabilities, this means taking an active role in their own care and treatment, and advocating for their needs and preferences. ",
    },
    {
      id: 2,
      content:
        "Overcoming barriers: People with disabilities face many barriers in their daily lives, from physical barriers in the built environment to societal barriers such as discrimination and stigma. Self-advocacy can help individuals with disabilities overcome these barriers by giving them the tools and confidence to speak up for themselves and demand equal treatment. ",
    },
    {
      id: 3,
      content:
        "Building relationships: Self-advocacy can also help people with disabilities build stronger relationships with their caregivers, family members, and friends. By advocating for their own needs and preferences, individuals with disabilities can communicate more effectively with those around them and build more positive, supportive relationships. ",
    },
    {
      id: 4,
      content:
        "Creating change: Finally, self-advocacy can be a powerful tool for creating systemic change and promoting social justice. By speaking out about their experiences and advocating for policy changes, people with disabilities can help create a more inclusive, equitable society. ",
    },
    {
      id: 5,
      content:
        "Self-advocacy is a critical skill for people with disabilities, empowering them to take control of their lives and overcome barriers to success. By developing this skill, individuals with disabilities can promote self-determination, build stronger relationships, and create positive change in their communities. If you or someone you know is interested in learning more about self-advocacy, contact us to learn about resources and support available.",
    },
  ];

  const pElements4 = [
    {
      id: 1,
      content:
        "Increased independence: Assistive technology can help people with disabilities live more independently by providing them with tools and devices to perform tasks they may have difficulty with otherwise. From mobility aids to communication devices, assistive technology can open up new opportunities for people with disabilities to live life on their own terms.",
    },
    {
      id: 2,
      content:
        "Improved accessibility: Assistive technology is also making the world a more accessible place for people with disabilities. From screen readers to wheelchair ramps, assistive technology is helping to level the playing field and ensure that people with disabilities have equal access to education, employment, and public spaces.",
    },
    {
      id: 3,
      content:
        "Enhanced communication: For people with communication disabilities, assistive technology is a game-changer. Devices such as speech generating devices, communication apps, and eye-tracking technology are giving individuals with communication disabilities the ability to express themselves more effectively and connect with others in new ways.",
    },
    {
      id: 4,
      content:
        "Greater inclusion: Finally, assistive technology is helping to promote greater inclusion of people with disabilities in all aspects of society. From accessible online learning platforms to smart home technology, assistive technology is creating new opportunities for people with disabilities to participate fully in their communities.",
    },
    {
      id: 5,
      content:
        "Assistive technology is transforming the lives of people with disabilities, empowering them to live more independently, access new opportunities, and connect with others in meaningful ways. If you or someone you know could benefit from assistive technology, contact us to learn more about the latest products and services available.",
    },
  ];

  const visiblePElments1 = showMore1 ? pElements1 : pElements1.slice(0, 3);
  const visiblePElments2 = showMore2 ? pElements2 : pElements2.slice(0, 3);
  const visiblePElments3 = showMore3 ? pElements3 : pElements3.slice(0, 3);
  const visiblePElments4 = showMore4 ? pElements4 : pElements4.slice(0, 3);

  return (
    <>
      <div className="blog-page">
        <div className="blog-post">
          <h2> The Benefits of Adaptive Sports for People with Disabilities</h2>
          <p className="blog-post-date">May 1, 2023</p>
          <p>
            People with disabilities face unique challenges when it comes to
            physical activity and exercise. However, participating in adaptive
            sports can offer a range of physical, social, and emotional
            benefits. In this blog post, we'll explore the many advantages of
            adaptive sports for people with disabilities.
          </p>
          <div onClick={toggleIsHidden1}>
            {visiblePElments1.map((p) => (
              <p key={p.id} style={{ display: isHidden1 ? "none" : "block" }}>
                {p.content}
              </p>
            ))}
            {pElements1.length > 3 && (
              <button onClick={toggleShowMore1}>
                {showMore1 ? "See Less" : "See More"}
              </button>
            )}
          </div>
          {/* <a href="#test">Read more</a> */}
        </div>
        <div className="blog-post">
          <h2>
            Navigating the Challenges of Online Learning with a Disability
          </h2>
          <p className="blog-post-date">April 1, 2023</p>
          <p>
            Online learning has become increasingly popular in recent years, but
            for people with disabilities, it can present unique challenges. In
            this blog post, we'll explore some of the common obstacles that
            individuals with disabilities may face when participating in online
            learning, and offer strategies for overcoming these challenges.
          </p>{" "}
          <div onClick={toggleIsHidden2}>
            {visiblePElments2.map((p) => (
              <p key={p.id} style={{ display: isHidden2 ? "none" : "block" }}>
                {p.content}
              </p>
            ))}
            {pElements2.length > 3 && (
              <button onClick={toggleShowMore2}>
                {showMore2 ? "See Less" : "See More"}
              </button>
            )}
          </div>
          {/* <a href="#test">Read more</a> */}
        </div>
        <div className="blog-post">
          <h2>The Importance of Self-Advocacy for People with Disabilities</h2>
          <p className="blog-post-date">April 10, 2023</p>
          <p>
            Self-advocacy is an essential skill for people with disabilities,
            empowering them to take control of their lives and ensure that their
            voices are heard. In this blog post, we''ll explore why
            self-advocacy is so important for people with disabilities, and
            offer tips and strategies for developing and honing this critical
            skill
          </p>
          <div onClick={toggleIsHidden3}>
            {visiblePElments3.map((p) => (
              <p key={p.id} style={{ display: isHidden3 ? "none" : "block" }}>
                {p.content}
              </p>
            ))}
            {pElements3.length > 3 && (
              <button onClick={toggleShowMore3}>
                {showMore3 ? "See Less" : "See More"}
              </button>
            )}
          </div>
          {/* <a href="#test">Read more</a> */}
        </div>
        <div className="blog-post">
          <h2>
            How Assistive Technology is Transforming the Lives of People with
            Disabilities
          </h2>
          <p className="blog-post-date">April 19, 2023</p>
          <p></p>
          <p>
            Assistive technology has come a long way in recent years, offering
            new tools and opportunities for people with disabilities to live
            more independently and achieve their goals. In this blog post, we'll
            explore the ways in which assistive technology is transforming the
            lives of people with disabilities, and highlight some of the latest
            and most innovative products on the market.
          </p>

          <div onClick={toggleIsHidden4}>
            {visiblePElments4.map((p) => (
              <p key={p.id} style={{ display: isHidden4 ? "none" : "block" }}>
                {p.content}
              </p>
            ))}
            {pElements4.length > 3 && (
              <button onClick={toggleShowMore4}>
                {showMore4 ? "See Less" : "See More"}
              </button>
            )}
          </div>
          {/* <a href="#test">Read more</a> */}
        </div>
      </div>
      {/* end of blog page */}
    </>
  );
};

export default Blog;
