import React from "react";
import "./EmployeeGroup.css";
import EmployeeGroupData from "./EmployeeGroupData";
import EmployeeGroupImg1 from "../../Image/CEO.jpeg";
import EmployeeGroupImg2 from "../../Image/HR.jpeg";
import EmployeeGroupImg3 from "../../Image/IT.jpeg";

const EmployeeGroup = () => {
  return (
    <>
      <div className="EmployeeGroup">
        <h1>We are proud to be team of:</h1>
        <p>Know more about us....!!</p>
        <EmployeeGroupData
          className="firstEmployeeGroup"
          heading="CEO"
          text="About me...!!"
          employeeGroupImage1={EmployeeGroupImg1}
        />
        <EmployeeGroupData
          className="firstEmployeeGroup-reverse"
          heading="Chairperson/HR Manager"
          text="About me...!!"
          employeeGroupImage1={EmployeeGroupImg2}
        />
      </div>
      <div>
        <EmployeeGroupData
          className="firstEmployeeGroup"
          heading="IT/Support Worker"
          text="About me...!!"
          employeeGroupImage1={EmployeeGroupImg3}
        />
      </div>
    </>
  );
};

export default EmployeeGroup;
