import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroContent from "../../components/HeroContent/HeroContent";
import ServicesImg from "../../Image/dncc2.jpeg";
import ServicesList from "../../components/ServiceList/ServicesList";

const Services = () => {
  // const appointment =() =>{
  //   alert("Under development!!")
  // }
  return (
    <>
      <Navbar />
      <HeroContent
        cName="hero"
        heroImage={ServicesImg}
        heroImagecName="heroImage"
      />
      <h1 style={{ textAlign: "center" }}> OUR SERVICES </h1>
      <ServicesList />
    </>
  );
};

export default Services;
