// FAQSection.js

import React, { useState } from "react";
import "./FAQSection.css";

const FAQSection = ({ faqData }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onQuestionClick = (index) => {
    setActiveIndex(index);
  };

  const renderQuestions = () => {
    return faqData.map((item, index) => {
      const active = index === activeIndex ? "active" : "";
      return (
        <>
          <div key={item.question} className="faq-item">
            <div
              className={`faq-question ${active}`}
              onClick={() => onQuestionClick(index)}
            >
              {item.question}
            </div>
            <div className={`faq-answer ${active}`}>{item.answer}</div>
          </div>
        </>
      );
    });
  };

  return <div className="faq-container">{renderQuestions()}</div>;
};

export default FAQSection;
