export const faqData = [
  {
    question: "What is NDIS?",
    answer:
      "The National Disability Insurance Scheme (NDIS) is a government-funded program in Australia that provides support and services to people with disabilities.",
  },
  {
    question: "Who is eligible for NDIS?",
    answer:
      "To be eligible for NDIS, you must be an Australian citizen or permanent resident with a permanent and significant disability that affects your ability to take part in everyday activities.",
  },
  {
    question: "How do I apply for NDIS?",
    answer:
      "You can apply for NDIS by filling out an access request form on the NDIS website or contacting the NDIS directly.",
  },
  {
    question: "What is the NDIA?",
    answer:
      "The National Disability Insurance Agency (NDIA) is the independent agency responsible for implementing the National Disability Insurance Scheme (NDIS). If you’re eligible for the NDIS, the NDIA will work with you and your family to help you identify the supports you need to live your life and achieve your goals.",
  },
  {
    question: "I’m over 65. Can I access the NDIS?",
    answer:
      "No. If you’re over 65, you can’t access the NDIS – but that doesn’t mean you won’t receive support. If you’re aged 65 or over and are currently receiving disability supports, you’ll continue to receive the same level of support as before, just not through the NDIS.",
  },
  {
    question: "Will I get more or less support under the NDIS?",
    answer:
      "If your needs aren’t currently being met, you may be provided with more support if it’s deemed reasonable and necessary by the NDIS. This is why it’s important to spend some time thinking about your needs and where your life could be improved ahead of your first NDIS meeting.",
  },
  {
    question: "Can I change what I need under the NDIS?",
    answer:
      "Yes, if your circumstances change. If something happens that affects the supports you need, just let the NDIS or your support provider know and they’ll work with you to review your plan. Your NDIS plan is also reviewed every 12 months. This allows you to assess the progress you’ve made towards achieving your goals and helps you to set new goals for the year ahead – which could also lead to a change in your support requirements.",
  },
  {
    question: "What is an NDIS service agreement?",
    answer:
      "An NDIS service agreement is a legal agreement between you and your service provider/s which outlines the support that will be provided for you under the NDIS. It describes how and when the support will be provided, as well as the costs and the fees you need to pay.",
  },
  {
    question: "How do I know which organisations provide supports in my area?",
    answer:
      "Contact your NDIS Local Area Co-ordinator for a list of service providers in your area. Alternatively, you can find a List of Registered Providers in New South Wales on the NDIS website.",
  },
];
