import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroContent from "../../components/HeroContent/HeroContent";
import ContactImg from "../../Image/dncc3.jpeg";
import ContactForm from "../Forms/ContactForm";

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <HeroContent
        cName="hero"
        heroImage={ContactImg}
        heroImagecName="heroImage"
      />
      <ContactForm />
    </>
  );
};

export default ContactUs;
