import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroContent from "../../components/HeroContent/HeroContent";
import ResourcesImg from "../../Image/dncc3.jpeg";
import Resources from "./Resources";

const ResourcesSection = () => {
  return (
    <>
      <Navbar />
      <HeroContent
        cName="hero"
        heroImage={ResourcesImg}
        heroImagecName="heroImage"
      />
      <Resources />
    </>
  );
};

export default ResourcesSection;
