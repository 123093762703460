import React from "react";
import "./Motto.css";

const Motto = () => {
  return (
    <div className="motto">
      <h1> DNCC</h1>
      <p>
        {/* Choose the service you like to.. and give us way to make you happy"{" "} */}
        Take part. Contribute to changing the world in disability.
      </p>
    </div>
  );
};
export default Motto;
