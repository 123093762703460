import { useEffect } from "react";

function DisableRightClick() {
  useEffect(() => {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, []);

  return null;
}

export default DisableRightClick;
