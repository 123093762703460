import "./Resources.css";
import { Component } from "react";
import { resources } from "./ResourceData";
import { Link } from "react-router-dom";

class Resources extends Component {
  render() {
    return (
      <div className="resources-container">
        <h1>Resources</h1>
        <div className="resources-list">
          {resources.map((resource, index) => (
            <div className="resource-item" key={index}>
              <div className="resource-icon">
              </div>
              <div className="resource-content">
                <h3>{resource.title}</h3>
                <p>{resource.description}</p>
                <Link to={resource.link}>
                <i className={resource.icon}></i>
                See more</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default Resources;
