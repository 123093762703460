import React from "react";
import "./Feedbacks.css";

import { ReactComponent as LQuote } from "../../svg/LQuote.svg";
import { ReactComponent as RQuote } from "../../svg/RQuote.svg";
import smiley from "../../Image/smiley.jpeg";

const FeedbackSection = () => {
  const feedbacks = [
    {
      name: "",
      feedback:
        "I was nervous about starting with a new disability provider, but this company has exceeded all of my expectations. The staff is knowledgeable, friendly, and always available to answer any questions I have. Thank you for all you do!",
      image: smiley,
      alt: "feedback from ...",
    },
    {
      name: "",
      feedback:
        "I have been using this disability provider for several months now, and I have been extremely impressed with their level of care and attention. They have really made a difference in my life and I would highly recommend them to anyone in need.",
      image: smiley,
      alt: "feedback from ...",
    },
    {
      name: "",
      feedback:
        "As someone with a disability, finding quality care can be a challenge. This provider has been a lifesaver for me. They have helped me navigate the healthcare system and provided me with the support I need to live my life to the fullest.",
      image: smiley,
      alt: "feedback from ...",
    },
  ];

  return (
    <div className="feedbacks-page">
      <h1>Client Testimonials: Hear What Our Clients Say About Us</h1>
      <div className="feedbacks-list">
        {feedbacks.map((feedback, index) => (
          <div key={index} className="feedback">
            {
              <img
                style={{ height: "50px", width: "50px" }}
                src={feedback.image}
                alt={feedback.alt}
              />
            }
            <LQuote className="quote-icon" />
            <div className="feedback-text">
              {feedback.feedback}
              <RQuote className="quote-icon" />
            </div>
            <div className="feedback-name">{feedback.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedbackSection;
