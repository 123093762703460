import React from "react";
import { ReactComponent as LQuote } from "../../svg/LQuote.svg";
import { ReactComponent as RQuote } from "../../svg/RQuote.svg";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="main">
      <section className="sections">
        <h1> Our Histroy</h1>
        <div className="about-grid">
          <p>
            <LQuote className="quote-icon" />
            1.&nbsp; With an innovative spectrum of disability services, we help
            individuals with disabilities live, study, work, and flourish.
          </p>
          <p>
            {" "}
            2.&nbsp; In New South Wales, a community-based organisation called
            Dignity and Choice Care offers high-quality services to assist those
            with disabilities, their families, and carers.{" "}
          </p>{" "}
          <p>
            {" "}
            3.&nbsp;Dignity & Choice Care is dedicated to embracing diversity
            and eradicating all types of prejudice while providing services for
            people with disabilities.
          </p>
          <p>
            4.&nbsp;Being a specialised provider of services for people with
            disabilities, we are renowned for our excellent support. Dignity and
            Choice Care is a certified provider under the National Disability
            Insurance Scheme (NDIS) to offer top-notch help in a variety of
            areas.
          </p>
          <p>
            5.&nbsp;Dignity and Choice Care is a certified provider under the
            National Disability Insurance Scheme (NDIS) to offer top-notch help
            in a variety of areas.
            <RQuote className="quote-icon" />
          </p>
        </div>
      </section>
      <section className="sections">
        <h1> Our Mission</h1>
        <div className="about-grid">
          <p>
            <LQuote className="quote-icon" />
            Our mission is to provide innovative and high-quality disability
            services that empower individuals to live fulfilling lives, with
            dignity, respect, and inclusion. We are committed to eradicating all
            types of prejudice and discrimination, and to promoting diversity
            and accessibility for all. Our goal is to help individuals with
            disabilities and their families live, study, work, and flourish in
            their communities, with the support they need to achieve their goals
            and aspirations.
            <RQuote className="quote-icon" />
          </p>
        </div>
      </section>
      <section className="sections">
        <h1> Our Vision</h1>
        <div className="about-grid">
          <p>
            <LQuote className="quote-icon" />
            Our vision is to create a world where individuals with disabilities
            are valued members of society, with equal rights, opportunities, and
            access to resources. We envision a society that embraces diversity,
            inclusion, and accessibility, and that recognizes the unique
            strengths and contributions of individuals with disabilities. As a
            disability service provider, we strive to be a leader in innovative
            and person-centered services, advocacy, and research, promoting
            positive social change and improving the quality of life for all
            individuals with disabilities.
            <RQuote className="quote-icon" />
          </p>
        </div>
      </section>
    </div>
  );
};
export default AboutUs;
