import React from "react";
import styles from "./FoundationContent.module.css";
import image1 from "../../Image/dncc_1.jpeg";
import image2 from "../../Image/dncc_.jpeg";
import FoundationContentData from "./FoundationContentData";
import ChosingUsData from "../ChosingUs/ChosingUsData";

function FoundationContent({ isTrue }) {
  return (
    <>
      <div className={styles.myComponent}>
        <div className={styles.right}>
          <div style={{ textAlign: "left" }}>
            {isTrue ? (
              <h1>{FoundationContentData.title}</h1>
            ) : (
              <h1>{ChosingUsData.title}</h1>
            )}
            {isTrue ? (
              <h1>{FoundationContentData.title2}</h1>
            ) : (
              <h1>{ChosingUsData.title2}</h1>
            )}
          </div>
          <div style={{ fontSize: "20px" }}>
            {isTrue ? (
              <p>{FoundationContentData.description}</p>
            ) : (
              <p>{ChosingUsData.description}</p>
            )}
          </div>
        </div>
        <div className={styles.left}>
          {isTrue ? (
            <img src={image1} alt="My" />
          ) : (
            <img src={image2} alt="choose" />
          )}
        </div>
      </div>
    </>
  );
}

export default FoundationContent;
