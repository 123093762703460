import React from "react";
import "./ServicesList.css";
import ServicesListData from "./ServicesListData";
import Group_Centre_Activities from "../../Image/Group_Centre_Activities.jpeg";
import Plan_Management from "../../Image/Plan_Management.jpeg";
import Participate_Community from "../../Image/Participate_Community.jpeg";
import Household_Task from "../../Image/Household_Task.jpeg";
import Development_Life_Skills from "../../Image/Development_Life_Skills.jpeg";
import Innov_Community_Participation from "../../Image/Innov_Community_Participation.jpeg";
import Daily_Tasks_and_Shared_Living from "../../Image/Daily_Tasks_and_Shared_Living.jpeg";
import Community_Nursing_Care from "../../Image/Community_Nursing_Care.jpeg";
import Assist_Travel_and_Transport from "../../Image/Assist_Travel_and_Transport.jpeg";
import Assist_Personal_Activities from "../../Image/Assist_Personal_Activities.jpeg";
import Assist_Personal_Activities_High from "../../Image/Assist_Personal_Activities_High.jpeg";

function ServicesList() {
  return (
    <div className="serviceslist">
      {/* <h1> List of services provided </h1> */}
      <div className="servicecard">
        <ServicesListData
          image={Group_Centre_Activities}
          heading="Group/Centre Activities"
          text="Our daily activity support services are designed to promote independence in personal activities of daily living. These services can be provided in the participant's own home, where our team can assist and/or supervise personal tasks to help develop the participant's skills and enable them to live as autonomously as possible. Our goal is to empower individuals with disabilities to live their lives to the fullest and achieve their goals with dignity and respect."
        />
        <ServicesListData
          image={Plan_Management}
          heading="Plan Management"
          text="Drawing from our team's direct experience and expertise in the sector, we have carefully curated a list of the most comprehensive and user-friendly systems available. We have identified four top recommended platforms for comparison, which have been selected based on their track record of delivering outstanding results and meeting the needs of our clients."
        />
        <ServicesListData
          image={Participate_Community}
          heading="Participate Community"
          text="Our aim is to promote social and recreational activities for individuals within their community. Our team understands that participating in community activities can be challenging for some individuals, especially those with disabilities. Therefore, we offer a range of tailored supports that can be provided in various settings to ensure that individuals can fully engage and enjoy these activities."
        />
      </div>
      <div className="servicecard">
        <ServicesListData
          image={Household_Task}
          heading="Household Task"
          text="We provide support workers who are trained to undertake a wide range of tasks, including cleaning stovetops, wiping down benchtops and inside fridges, vacuuming or mopping floors, dusting, dishwashing, changing bed linen, washing, drying, and doing essential ironing. Our support workers are dedicated to ensuring that your living space is clean, comfortable, and safe, so that you can focus on the important things in life."
        />
        <ServicesListData
          image={Development_Life_Skills}
          heading="Development-Life Skills"
          text="Our daily living and life skills development program aims to enhance the participant's or their caregiver's self-sufficiency by providing training and developmental activities. These activities aim to increase their capacity to live independently, while also enhancing their quality of life."
        />
        <ServicesListData
          image={Innov_Community_Participation}
          heading="Innov Community Participation"
          text="We believe in personalized care, and therefore, each of our services is customized to meet the individual needs and goals of the participant. We work closely with the participant and their support system to ensure that they receive the most optimal service that aligns with their unique needs. Our team is equipped to provide comprehensive support in various areas, including but not limited to: skill development, health and wellbeing, community participation, and goal setting."
        />
      </div>
      <div className="servicecard">
        <ServicesListData
          image={Daily_Tasks_and_Shared_Living}
          heading="Daily Tasks and Shared Living"
          text="We offer assistance and supervision with daily living tasks in a shared living environment, whether temporary or ongoing, with a focus on developing each individual's skills to live as autonomously as possible. This includes short-term accommodation and support, all tailored to meet the unique needs and goals of each participant."
        />
        <ServicesListData
          image={Community_Nursing_Care}
          heading="Community Nursing Care"
          text="Community Nursing Care is a specialized service that caters to individuals with complex support needs, requiring the expertise of a qualified and experienced medical professional. The service is designed to meet the unique and intricate health requirements of the participant, ensuring that they receive the highest quality of care. Our team of skilled nursing professionals is equipped to provide this specialized care, working closely with the participant to develop a personalized care plan that addresses their specific needs. We are committed to delivering this vital service with compassion, skill, and the utmost professionalism."
        />
        <ServicesListData
          image={Assist_Travel_and_Transport}
          heading="Assist-Travel and Transport"
          text="Transportation is an essential aspect of daily living, providing access to work, education, medical appointments, and community activities. At times, it can be challenging for individuals to navigate transportation on their own. That's where we come in, providing the necessary support to ensure that participants can engage in social, economic, and daily life activities. We believe that transportation should not be a barrier to independence and community participation. As such, we offer tailored transportation services that address the unique needs of each participant, enabling them to achieve their goals and lead fulfilling lives."
        />
      </div>
      <div className="create-even-space-last"></div>
      <div className="servicecard-last">
        <ServicesListData
          image={Assist_Personal_Activities}
          heading="Assist-Personal Activities"
          text="Daily activities support is a critical component of a participant's ability to live independently. We understand the importance of providing assistance that is tailored to individual needs, and can be provided within the comfort and familiarity of their own home. Our trained support workers provide personalized support and supervision to help participants develop the necessary skills to complete daily activities independently. By promoting self-sufficiency, we empower our participants to live their lives on their own terms, with dignity and respect."
        />
        <div className="create-even-space"></div>
        <ServicesListData
          image={Assist_Personal_Activities_High}
          heading="Assist Personal Activities High"
          text="At our organization, we are dedicated to providing top-quality individualized supports that promote maximum independence in daily living activities. Our highly trained staff are available to assist and supervise individuals with their personal tasks of daily living, empowering them to live with a greater degree of autonomy. Our services are tailored to meet the unique needs of each participant and can be provided in a variety of settings, including the comfort of their own home. We strive to create a supportive environment that fosters independence and allows individuals to achieve their full potential."
        />
      </div>
    </div>
  );
}

export default ServicesList;
