import React from "react";
import { Link } from "react-router-dom";
import "./SocialNetwork.css";

const TopFooter = () => {
  return (
    <>
      <div className="top-text">
        <h2>Keep in touch with us:</h2>
      </div>
      <div className="top">
        <Link to="https://www.facebook.com/dignitynchoicecare" target="_blank">
          <i className="fa-brands fa-facebook-square"></i>
        </Link>
        <Link
          to="https://www.instagram.com/dignitynchoicecare/"
          target="_blank"
        >
          <i className="fa-brands fa-instagram-square"></i>
        </Link>
        <Link to="https://www.tiktok.com/@dignitynchoicecare" target="_blank">
          <i class="fa-brands fa-tiktok"></i>
        </Link>
        <Link to="https://twitter.com/DignitynCcare" target="_blank">
          <i className="fa-brands fa-twitter-square"></i>
        </Link>
        <Link
          to="https://www.linkedin.com/in/dignity-n-choice-care/"
          target="_blank"
        >
          <i class="fa-brands fa-linkedin"></i>
        </Link>
        <Link to="https://www.youtube.com/@dignitynchoicecare" target="_blank">
          <i class="fa-brands fa-youtube"></i>
        </Link>
      </div>
    </>
  );
};

export default TopFooter;
