import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroContent from "../../components/HeroContent/HeroContent";
import ApplyImg from "../../Image/dncc3.jpeg";

const Apply = () => {
  return (
    <>
      <Navbar />
      <HeroContent
        cName="hero"
        heroImage={ApplyImg}
        heroImagecName="heroImage"
      />
    </>
  );
};
export default Apply;
