import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import HeroContent from "../../components/HeroContent/HeroContent";
import BlogImg from "../../Image/dncc4.jpeg";
import Blog from "./Blog";

const BlogSection = () => {
  return (
    <>
      <Navbar />
      <HeroContent
        cName="hero"
        heroImage={BlogImg}
        heroImagecName="heroImage"
      />
      <h1 style={{ textAlign: "center" }}> BLOG </h1>
      <Blog/>
    </>
  );
};

export default BlogSection;
