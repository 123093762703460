import React from "react";
import "./News.css";
import NMHS from "../../Image/NMHS.jpeg";
import ndisUpdate from "../../Image/ndis_update.jpeg";
import gov_aus from "../../Image/aus_gov.jpeg";

const News = () => {
  const news = [
    {
      id: 1,
      title: "National Mental Health Sector March 2023 Communique",
      date: "21 April 2023",
      author: "",
      image: NMHS,
      summary: "",
      link: "https://www.miragenews.com/national-mental-health-sector-march-2023-991064/",
    },
    {
      id: 2,
      title:
        "Media release from the Minister - Pilot program to trial support for infants showing early behavioural signs of autism",
      date: "18 April 2023",
      author: "",
      image: ndisUpdate,
      summary: "",
      link: "https://www.ndis.gov.au/news/9068-media-release-minister-pilot-program-trial-support-infants-showing-early-behavioural-signs-autism",
    },
    {
      id: 3,
      title:
        "Australian Disability Enterprises (ADE) to open employment research",
      date: "14 April 2023",
      author: "",
      image: gov_aus,
      summary: "",
      link: "https://www.dss.gov.au/disability-and-carers-programs-services-for-people-with-disability/supported-employment",
    },
    {
      id: 4,
      title:
        "Media release from the Minister - NDIA to strengthen participant safety",
      date: "10 April 2023",
      author: "",
      image: ndisUpdate,
      summary: "",
      link: "https://www.ndis.gov.au/print/pdf/node/9053",
    },
  ];

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Latest News</h1>
      <div className="news-container">
        <div className="news-grid">
          {news.map((item) => (
            <div key={item.id} className="news-item">
              <a href={item.link}>
                <img src={item.image} alt={item.title} />
                <h2>{item.title}</h2>
                <p className="news-date">{item.date}</p>
                <p className="news-summary">{item.summary}</p>
                <p className="news-author">{item.author}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default News;
